.img-wrapper {
  display: block;
  margin-left: 2%;
  margin-top: 2%;
}


h5:first-child {
  background-color: #f8d467;
  border-radius: 4px;
  width: 125px;
  color: #001529;
  font-weight: 600;
  padding: 4px;
  text-align: center;
  font-size: "15px";
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn::selection {
  color: #001529;
}

li {
  font-weight: 600
}

.profile-header {
  display: flex;
  align-items: center;
}

.profile-title {
  font-size: 14px;
  font-weight: 600;
  color: #007bff;
  margin-right: 20px;
}

.doc-title {
  font-size: 18px;
  font-weight: 700;
  color: #111111;
  align-self: baseline;
  margin-top: 2px;
}

/* Keyframes for fade-in animation */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Keyframes for slide-in animation */
@keyframes slideIn {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Apply animations */
.fade-in {
  animation: fadeIn 0.5s ease-in-out forwards;
}

.slide-in {
  animation: slideIn 0.7s ease-in-out forwards;
}

/* Add animation delay for staggered appearance */
.fade-in-delay {
  animation-delay: 0.2s;
}

.slide-in-delay {
  animation-delay: 0.4s;
}
