:root {
  --primary-color: #4a90e2;
  --secondary-color: #0056b3;
  --hover-color: #218838;
  --shadow-light: 0 4px 8px rgba(0, 0, 0, 0.1);
  --transition-fast: 0.3s ease-in-out;
}

.grid-container {
  display: grid;
  gap: 20px;
  padding: 20px;
  background-color: whitesmoke;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 2fr));
  gap: 16px;
  flex-grow: 1; /* Ensure it takes up the remaining height */
  min-height: 100%;
}

.card {
  background: #fff;
  border-radius: 10px;
  box-shadow: var(--shadow-light);
  padding: 20px;
  text-align: center;
  box-sizing: border-box;
}

.document-image {
  width: 100%;
  max-width: 400px;
  height: auto;
  cursor: pointer;
  border-radius: 8px;
  transition: transform var(--transition-fast);
  padding: 2%;
  will-change: transform;
}

.document-image:hover {
  transform: scale(1.05);
}

.save-all-button {
  transition: background-color var(--transition-fast);
}

.save-all-button:hover {
  background-color: var(--hover-color);
}

.controls {
  margin-top: 10px;
  background-color: var(--secondary-color);
}

.ant-radio-inner {
  width: 16px;
  height: 16px;
  border-width: 2px;
  border-color: var(--primary-color);
}

.ant-radio-inner::after {
  width: 10px;
  height: 10px;
  background-color: var(--primary-color);
}

.ant-radio:hover .ant-radio-inner {
  border-color: #007bff;
}

.ant-radio-checked .ant-radio-inner {
  border-color: var(--primary-color);
  background-color: #fff;
}

.ant-radio-checked .ant-radio-inner::after {
  background-color: var(--primary-color);
}

.ant-radio-input:focus + .ant-radio-inner {
  box-shadow: 0 0 0 3px rgba(74, 144, 226, 0.2);
}

.fade-container {
  opacity: 1;
  transition: opacity var(--transition-fast);
}

.fade-container.fade-out {
  opacity: 0;
}