.container-data {
  display: flex;
  margin-top: 4%;
}

.profile-column {
  flex: 1;
  margin: 0 5px;
}

.profile-item {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
}

.profile-label {
  color: white;
  width: 150px;
  text-align: left;
  font-weight: bold;
  border-radius: 5px;
  padding: 2px;
}

.profile-value {
  margin-left: 10px;
  font-size: 14px;
  color: #333;
}


.profile-container {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  border-radius: 10px;
}

.profile-column {
  flex: 1;
}

.profile-item {
  margin-top: 2px;
  display: flex;
  height: auto;
  align-items: center;
  margin-bottom: 4px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: background-color 0.3s ease;
}

.profile-item:hover {
  background-color: #f1f1f1;
}

.profile-label {
  font-weight: bold;
  color: #333;
  margin-right: 10px;
}

.profile-value {
  font-size: 14px;
  color: #2e2e2e;
}

@media (max-width: 768px) {
  .profile-container {
    flex-direction: column;
  }
}