h1.page-header {
    color: white;
    margin-left: 7%;
    margin-bottom: 0;
    margin-top: -3%;
}

.addition-to-img-container {
    height: 45px;
}

.header-wrapper {
    display: inline-flex;
    margin-left: -2.3%;
}

.page-header:after {
    content: "PEDIA";
    position: relative;
    color: #FFD700;
}

:where(.css-dev-only-do-not-override-vryruh).ant-layout-header {
    line-height: 0;
}

.flex-header-container {
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
}

.flex-header-child {
    flex: 1;
    margin-left: 0;
}

.flex-child:first-child {
    margin-right: 15px;
}

.doc-header {
    color: black;
    display: inline-flex;
    width: 100%;
    align-items: center;
}

.flag {
    border-radius: 1px;
    border-color: rgb(204, 203, 203);
    box-shadow: 0px 8px 36px rgb(223, 223, 223);
}

.title {
    margin-top: 2%;
    margin-left: 1%;
    font-size: 1.1em;
    font-weight: 700;
}

.align-top-menu {
    float: right;
    text-align: right;
}

.header-buttons {
    display: flex;
    gap: 10px;
}