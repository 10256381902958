
.thumbnails {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.thumbnail {
  max-width: 100px;
  height: auto;
  border-radius: 4px;
}

.image-container {
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.thumbnail-container {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 8px;
  display: inline;
  width: 150px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  text-align: center;
  background-color: #fff;
}

img {
  max-width: -webkit-fill-available;
}
.image-row {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  gap: 10px;
  justify-content: flex-start;
}

.image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 100px;
  overflow: hidden;
  border-radius: 8px;
  border: 1px solid #ddd;
  flex-shrink: 0;
}

.image-container img {
  display: block;
  max-width: 100%;
  max-height: 100%;
}

.card-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  gap: 16px;
}

.card-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  gap: 14px;
}

.custom-card {
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.3s, box-shadow 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.custom-card:hover {
  transform: translateY(-8px);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.custom-card .image-container {
  margin-top: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.custom-card img {
  border-radius: 8px;
}

.card-container {
  padding: 20px;
}

.card-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 24px; /* Increased gap for better spacing */
}

.custom-card {
  border-radius: 12px; /* Rounded corners */
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  background-color: #ffffff; /* Background color for contrast */
}

.custom-card:hover {
  transform: translateY(-8px); /* Slight lift on hover */
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15); /* Enhanced shadow on hover */
}

.custom-card .ant-card-meta-title {
  font-size: 16px;
  font-weight: bold;
  color: #333333; /* Darker color for better visibility */
  display: flex;
  align-items: center;
  gap: 8px; /* Space between flag and text */
}

.custom-card .ant-card-meta-description {
  font-size: 14px;
  line-height: 1.5;
  color: #555555; /* Softer color for readability */
}

.image-container {
  margin-top: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f9f9f9; /* Light background for image container */
  border-top: 1px solid #f0f0f0; /* Border for separation */
  padding: 8px; /* Padding around the image */
}

.image-container img {
  width: 100%;
  max-width: 260px; /* Optional: Limit max width of images */
  height: 150px;
  object-fit: cover;
  border-radius: 8px; /* Rounded corners for images */
}

.card-container {
  padding: 0px;
}

.card-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  gap: 16px; /* Increased gap for better spacing */
}

.custom-card {
  border-radius: 12px; /* Rounded corners */
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  background-color: #ffffff; /* Background color for contrast */
}

.custom-card:hover {
  transform: translateY(-8px); /* Slight lift on hover */
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15); /* Enhanced shadow on hover */
}

.custom-card .ant-card-meta-title {
  font-size: 15px;
  font-weight: bold;
  color: #333333; 
  display: flex;
  align-items: center;
  gap: 8px; 
}

.custom-card .ant-card-meta-description {
  font-size: 14px;
  line-height: 1;
  color: #555555; /* Softer color for readability */
}

.image-container {
  width: 100%;
  height: 160px;
  margin-top: 16px;
  display: flex;
  background-color: #f9f9f9; 
  border-top: 1px solid #f0f0f0; 
}

div.image-container{
  gap: 12px;
}

.image-container img {
  width: 100%;
  max-width: 400px;
  height: 180px;
  border-radius: 4px;
}

:where(.css-dev-only-do-not-override-vryruh).ant-card .ant-card-body{
  padding: 16px;
}

div.title{
  text-align: -webkit-center;
}