
* {
  box-sizing: border-box;
}
html{
  background-color: #F3F5FB;
}
body, html{
  padding:0;
  margin:0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body::-webkit-scrollbar {
  width: 12px;            
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  background: whitesmoke;
  width: 12px;
}
