.document-data-container {
    padding: 10px;
    border-radius: 10px;
}

.document-data-container .ant-tabs {
    height: 100%;
}

.document-data-container .ant-tabs-tabpane {
    max-height: 350px;
    height: 250px;
}

@media (min-height: 600px) {
    .document-data-container .ant-tabs-tabpane {
        height: 60vh;
    }
}

@media (min-height: 800px) {
    .document-data-container .ant-tabs-tabpane {
        height: 70vh;
    }
}

@media (min-height: 1000px) {
    .document-data-container .ant-tabs-tabpane {
        height: 80vh;
    }
}

ul {
    margin-top: -5px;
    list-style-type: none;
    padding: 0;
    max-height: 100%;
}

ul li {
    padding: 2px 0;
    border-bottom: 1px solid #ddd;
}

@media (max-width: 768px) {
    .document-data-container {
        padding: 4px;
    }
}

/* Style for the scrollable content area */
.ant-tabs-content-holder {
    overflow: auto;
    max-height: calc(100vh - 200px); /* Dynamically adjust height */
    padding: 16px;
    background-color: #ffffff;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.05);
  }
  
  /* Scrollbar customization */
  .ant-tabs-content-holder::-webkit-scrollbar {
    width: 10px; /* Scrollbar width */
    height: 10px; /* Scrollbar height for horizontal scrolling */
  }
  
  .ant-tabs-content-holder::-webkit-scrollbar-track {
    background: #f0f0f0; /* Track background color */
    border-radius: 10px; /* Rounded edges for the track */
  }
  
  .ant-tabs-content-holder::-webkit-scrollbar-thumb {
    background: #bfbfbf; /* Scrollbar thumb color */
    border-radius: 10px; /* Rounded edges for the thumb */
    border: 2px solid #f0f0f0; /* Adds spacing between the thumb and track */
  }
  
  .ant-tabs-content-holder::-webkit-scrollbar-thumb:hover {
    background: #8c8c8c; /* Darker thumb color on hover */
  }
  
  /* For Firefox (scrollbar-gutter) */
  .ant-tabs-content-holder {
    scrollbar-width: thin; /* Slim scrollbar */
    scrollbar-color: #bfbfbf #f0f0f0; /* Thumb color and track color */
  }
  