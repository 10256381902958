div .select-dropdown {
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 5%;
  box-shadow: 1px 1px 0.5px #d4d4d1;
}

.select-dropdown {
  width: auto;
}

.ant-layout-sider-children {
  background-color: whitesmoke;
  border: 2px solid #c7c6c0;
}

.wrapper-slider {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}


.addition-to-img-container {
  height: 45px;
}

.flex-header-container img {
  margin-top: 10px;
  margin-left: 1%;
}

.flex-header-container {
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 10px;
}

.flex-header-child {
  flex: 1;
  margin-left: 0;
}

.flex-child:first-child {
  margin-right: 15px;
}

.doc-header {
  color: black;
  display: inline-flex;
  width: 100%;
  align-items: center;
}

.flag {
  border-radius: 1px;
  border-color: rgb(204, 203, 203);
  box-shadow: 0px 8px 36px rgb(223, 223, 223);
}

.title {
  margin-top: 2%;
  margin-left: 1%;
  font-size: 1.1em;
  font-weight: 700;
}

.align-top-menu {
  float: right;
  text-align: right;
}

.card-container {
  margin: 2%;
  max-width: 95%;
}

.title {
  font-weight: 600;
  color: black;
}

.content {
  display: inline;
}

.wrapper-slider {
  display: flex;
  justify-content: center;
}


.btns-filter {
  display: inline-flex;
}

.ant-layout-content {
  min-height: 680px;
}

@media (max-height: 790px) {
  .ant-layout-content {
    height: 680px;
    overflow: auto;
  }
}

@media (min-height: 900px) {

  .ant-layout-content {
    height: 900px;
    overflow: auto;
  }
}


@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.spinner:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #F4D03F;
  border-top-color: #F4D03F;
  animation: spinner .6s linear infinite;
}

/* sizes */
#main-wrap>div {
  min-height: 450px;
}

/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loading:not(:required):after {
  content: '';
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: spinner 1500ms infinite linear;
  -moz-animation: spinner 1500ms infinite linear;
  -ms-animation: spinner 1500ms infinite linear;
  -o-animation: spinner 1500ms infinite linear;
  animation: spinner 1500ms infinite linear;
  border-radius: 0.5em;
  -webkit-box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0, rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0, rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.5) -1.5em 0 0 0, rgba(0, 0, 0, 0.5) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.75) 0 -1.5em 0 0, rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
  box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0, rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0, rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) -1.5em 0 0 0, rgba(0, 0, 0, 0.75) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.75) 0 -1.5em 0 0, rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

#animation div {
  animation: fadein 0.8s;
  -moz-animation: fadein 0.8s;
  /* Firefox */
  -webkit-animation: fadein 0.8s;
  /* Safari and Chrome */
  -o-animation: fadein 0.8s;
  /* Opera */
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-moz-keyframes fadein {

  /* Firefox */
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-webkit-keyframes fadein {

  /* Safari and Chrome */
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-o-keyframes fadein {

  /* Opera */
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.free-search {
  margin: 5%;
  color: rgb(92, 92, 92);
}


input::-webkit-input-placeholder {
  color: rgb(92, 92, 92) !important;
}

.btns-filter {
  display: flex;

}
